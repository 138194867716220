import React, {Component} from 'react';

import Main from "./MainComponent";
import {Link} from "react-router-dom";

class HeaderComponent extends Component {
render(){

    function navToggle(){
        const navbarMenu = document.querySelector('#nav-links');
        navbarMenu.classList.toggle('is-active')
    }
        return (
            <React.Fragment>
                <div className='container'>

                    <nav className="navbar">
                        <div className="navbar-brand">
                            <Link className="navbar-item" to='/'>
                                <img src='../Assets/Images/LogoTechkids Footer.svg'
                                     alt="Techkids: a modern CSS framework based on Flexbox" width="112" height="28" />
                            </Link>
                            <div className="navbar-burger" data-target="navbarExampleTransparentExample" id='burger' onClick={navToggle}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>

                        <div id="navbarExampleTransparentExample" className="navbar-menu" id='nav-links'>
                            <div className="navbar-end">
                                <Link className="navbar-item" to='/' onClick={navToggle}>
                                    Inicio
                                </Link>
                                <div className="navbar-item has-dropdown is-hoverable">
                                    <Link className="navbar-link" to='/' onClick={navToggle}>
                                        Cursos
                                    </Link>
                                    <div className="navbar-dropdown is-boxed">
                                        <Link className="navbar-item" to={`/course/0`} onClick={navToggle}>
                                            Programación de Videojuegos con Scratch
                                        </Link>
                                        <Link className="navbar-item" to={`/course/3`} onClick={navToggle}>
                                            Diseño Gráfico
                                        </Link>
                                        <Link className="navbar-item" to={`/course/1`} onClick={navToggle}>
                                            Dibujo & Pintura
                                        </Link>
                                        <Link className="navbar-item" to={`/course/2`} onClick={navToggle}>
                                            Pintura con Acuarela
                                        </Link>
                                        <Link className="navbar-item" to={`/course/4`} onClick={navToggle}>
                                            Diseño 3D
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </nav>

                </div>
            </React.Fragment>
        );
}

}


export default HeaderComponent;