import React from 'react';
import { useParams } from "react-router-dom";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid,brands } from '@fortawesome/fontawesome-svg-core/import.macro'


const CourseDetail = (props) => {


       // console.log(this.state.courses.filter((course) => course.name)[0]);
        let { courseId } = useParams();

        function horas(horarios){
            let aux="";
            for(let h of horarios){
                aux=aux+h+'<br/>'
                console.log(h)
            }
            return aux;
        }

        return (

                <>

                <div className="container">
                    <nav className="breadcrumb mt-5 ml-5" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link  to='/'>
                                    <span className="icon is-small">
                                        <FontAwesomeIcon icon={solid('house')} />
                                    </span>
                                    <span>Inicio</span>
                                </Link>
                            </li>
                            <li className="is-active">
                                <a href="#">
                                    <span>Detalle del Curso</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>



                    <section className="section mt-2">
                        <div className="container is-flex is-justify-content-center">
                            <video width="1280" height="720" poster="../Assets/Images/1280x720.png" controls
                                   autoPlay>
                                <source src={props.course.video} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </section>


                <section className="section">
                    <div className="container">

                        <div className="media mb-3 mt-3">
                            <div className="media-left">
                                <figure className="image is-64x64">
                                    <img src={props.course.icon} alt="Placeholder image" />
                                </figure>
                            </div>
                            <div className="media-content">

                                <p className="title">{props.course.course}</p>

                                <div className="is-flex mt-0 mb-3">
                                    <div className="  is-flex is-justify-content-end">
                                        <figure className="image is-32x32">
                                            <img className="is-rounded" src={props.course.perfil} alt="Placeholder image" />
                                        </figure>
                                    </div>
                                    <div className=" is-flex is-align-items-center ml-4">
                                        <p className="title is-6 has-text-left">Por {props.course.profesor}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="columns">

                            <div className="column">
                                <figure className="image is-256x256">
                                    <img src={props.course.image} />
                                </figure>
                            </div>

                            <div className="column">

                                <h4 className="title is-5">
                                    <span className="icon is-small mr-3">
                                      <FontAwesomeIcon icon={solid('chalkboard-user')} />
                                    </span>
                                    {props.course.titleDes}
                                </h4>
                                <div className="content">

                                    {props.course.description }

                                    <p className="subtitle mt-5">
                                        <span className="icon is-small">
                                          <FontAwesomeIcon icon={solid('circle-info')} />
                                        </span>
                                        <strong className="ml-3">Detalles del curso:</strong>
                                    </p>
                                    💳 Costo: {props.course.costo} Bs<br/>
                                    📅 Inicio: {props.course.inicio} <br/>
                                    💻 Duración: {props.course.duracion} <br/>
                                    ⏰ Horarios disponibles:<br/>
                                    <ul>
                                        {props.course.horario.map((horario)=>{
                                            return(
                                                <li>{horario}</li>
                                            );
                                        })}
                                    </ul>
                                    🧑 Edad: {props.course.edad} <br/>
                                    👧 💻 Modalidad: 100% virtual, clases en vivo<br/>

                                    <p className="subtitle mt-5"><strong>🔖 Beneficios:</strong></p>
                                    📜Certificado Digital<br/>
                                    📚Presentación de un proyecto aplicativo<br/>
                                    🎞Clases Grabadas<br/>
                                    👨💻 🗂 Acceso a recursos gráficos y audiovisuales<br/>
                                    🧑‍💻Enseñanza personalizada, el estudiante será guiado por el profesor y podrá consultar sus dudas.<br/>
                                    <br/>
                                    Para Inscripciones y más detalles escríbenos a:<br/>
                                    📲 WhatsApp : 77412744<br/>
                                    <br/>
                                    📨 Link directo:
                                    <a className="button is-primary ml-4" href="https://wa.me/59177412744?text=Hola+Techkids" target="_blank">
                                    <span className="icon is-small">
                                        <FontAwesomeIcon icon={brands('whatsapp')} />
                                    </span>
                                        <span>WhatsApp</span>
                                    </a>
                                </div>
                            </div>


                        </div>

                        <div className="media mt-6 mb-6">
                            <div className="media-left">
                                <figure className="image is-64x64">
                                    <img className="is-rounded" src={props.course.perfil} alt="Placeholder image" />
                                </figure>
                            </div>
                            <div className="media-content">
                                <p className="title is-4">{props.course.profesor}</p>
                                <p className="subtitle is-5 has-text-link">{props.course.cargo}</p>
                                <p className="subtitle is-6">Profesor del curso {props.course.course}</p>
                            </div>
                        </div>





                    </div>
                </section>


                </>


        );

}

export default CourseDetail;