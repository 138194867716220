export const COURSES =
    [
        {
            id: 0,
            course:'Programación de Videojuegos con Scratch',
            titleDes:'CURSO: PROGRAMACIÓN DE VIDEOJUEGO',
            image: '../Assets/Images/scratch.jpg',
            icon: '../Assets/Images/space.png',
            video:'../Assets/Videos/scratch.mp4',
            costo:'200',
            inicio:'Lunes 14 Marzo',
            duracion:'2 semanas, clases de Lunes a Viernes',
            horario:['09:00 a 10:30 Mañana', '17:00 a 18:30 Tarde' ],
            edad:'Dirigido a niños mayores de 8 años',
            profesor:'Ronald Chipana Wariste',
            perfil:'../Assets/Images/ronald.jpg',
            cargo:'Programador de Sistemas',
            description:'En este curso aprenderemos a crear nuestros propios videojuegos de una manera %100 practica y divertida. veremos conceptos básicos de programación, herramientas, recursos y etapas para el desarrollo de un videojuego.',
        },
        {
            id: 1,
            course:'Dibujo & Pintura',
            titleDes:'CURSO: DIBUJO & PINTURA',
            image: '../Assets/Images/dibujo.jpg',
            icon: '../Assets/Images/dibujo.png',
            video:'../Assets/Videos/dibujo.mp4',
            costo:'200',
            inicio:'Miércoles 9 Marzo',
            duracion:'2 semanas, clases de Lunes a Viernes',
            horario:['17:30 a 19:00 Tarde' ],
            edad:'Dirigido a niños mayores de 8 años',
            profesor:'Josue Calle Mamani',
            perfil:'../Assets/Images/josue.jpg',
            cargo:'Diseñador Grafico & Artista Plastico',
            description:'Con el curso tu niñ@ puede expresar toda su creatividad y desarrollar sus capacidades y talentos, además de comprender los principios de composición descubrirá su propio estilo de representación de la realidad, nuestros niños han demostrado grandes resultados 👧🧒',
        },
        {
            id: 2,
            course:'Pintura con Acuarela',
            titleDes:'CURSO: PINTURA CON ACUARELA',
            image: '../Assets/Images/acuarela.jpg',
            icon: '../Assets/Images/paint.png',
            video:'../Assets/Videos/dibujo.mp4',
            costo:'200',
            inicio:'Miércoles 9 Marzo',
            duracion:'2 semanas, clases de Lunes a Viernes',
            horario:[ '15:30 a 17:00 Tarde' ],
            edad:'Dirigido a niños mayores de 8 años',
            profesor:'Josue Calle Mamani',
            perfil:'../Assets/Images/josue.jpg',
            cargo:'Diseñador Grafico & Artista Plastico',
            description:'Con el curso tu niñ@ puede expresar toda su creatividad y desarrollar sus capacidades y talentos, además de comprender los principios de composición descubrirá su propio estilo de representación de la realidad, nuestros niños han demostrado grandes resultados 👧🧒',
        },
        {
            id: 3,
            course:'Diseño Gráfico',
            titleDes:'CURSO: DISEÑO GRÁFICO',
            image: '../Assets/Images/photoshop.jpg',
            icon: '../Assets/Images/diseno-grafico.png',
            video:'../Assets/Videos/photoshop.mp4',
            costo:'150',
            inicio:'Lunes 14 Marzo',
            duracion:'2 semanas, clases de Lunes a Viernes',
            horario:['14:00 - 15:30 Tarde', '16:30 - 18:00 Tarde' ],
            edad:'Dirigido a niños y jóvenes de 9 a 15 años👧',
            profesor:'Melvy Ancieta',
            perfil:'../Assets/Images/melvy.jpg',
            cargo:'Comunicadora Social & Diseñadora Gráfica',
            description:'🤯🐉 Si tienes un niño/a cuya imaginación te sorprende, en el Curso de Diseño Gráfico le proporcionamos una educación  didáctica para acercarle al lenguaje visual y gráfico potenciando su  creatividad y la imaginación, así como la capacidad de producir mensajes e imágenes originales basándonos en su talento natural 🌈🏜',
        },
        {
            id: 4,
            course:'Diseño y Modelado 3D',
            titleDes:'CURSO: DISEÑO Y MODELADO 3D',
            image: '../Assets/Images/3d.jpg',
            icon: '../Assets/Images/modelado-3d.png',
            video:'../Assets/Videos/3d.mp4',
            costo:'200',
            inicio:'Lunes 14 Marzo',
            duracion:'2 semanas, clases de Lunes a Viernes',
            horario:['14:00 a 15:30 Tarde' ],
            edad:'Dirigido a niños mayores de 8 años',
            profesor:'Ronald Chipana Wariste',
            perfil:'../Assets/Images/ronald.jpg',
            cargo:'Programador de Sistemas',
            description:'El curso de diseño en tres dimensiones para niños está pensado para que los estudiantes puedan explorar su imaginación y creatividad. En el curso los  niños aprenderán a manipular objetos en 3D para crear y modelar sus primeros diseños y modelos. adquiriendo nuevas habilidades digitales así como también aprendiendo desde muy temprana edad a manejar un Software 3D,  así como también se familiarizaran con conceptos, términos, técnicas de Ingeniería y arquitectura.   ',
        }
    ];