import React, {Component} from 'react';
import {Link} from "react-router-dom";
import gsap from 'gsap';
import {COURSES} from "../shared/courses";

class HomeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses:COURSES
        };
    }
    render() {
        function itemOver(item){
            console.log(item);
            gsap.to(item, {duration: 0.5, x: 20, ease: "elastic"});
        }

        function itemOut(item){
            console.log(item);
            gsap.to(item, {duration: 0.5, x: -20});
        }

            return (
                <div>
                    <section className="hero is-large mb-6 headerStyle">
                        <div className="hero-body">
                            <div className="container has-text-centered">
                                <p className="title">
                                    TechKids
                                </p>
                                <p className="subtitle">
                                    FORMANDO HABILIDADES STEAM
                                </p>
                            </div>
                        </div>
                    </section>




                    <section class="section">

                        <div className="container">
                            <p className="title">Cursos Disponibles</p>
                        </div>

                        <div className="columns is-mobile is-centered">

                            <div className="column is-12-mobile is-10-tablet is-8-desktop is-5-fullhd">
                                <Link to={`/course/0`}>
                                    <div className="card cardCurso"  id="item1" onMouseOver={() => itemOver("#item1")} onMouseOut={() => itemOut("#item1")}>

                                        <div className="card-content">
                                            <div className="media">
                                                <div className="media-left">
                                                    <figure className="image is-64x64">
                                                        <img src='../Assets/Images/space.png' alt="Placeholder image" />
                                                    </figure>
                                                </div>
                                                <div className="media-content">
                                                    <p className="title is-4">Programación de Videojuegos</p>
                                                    <p className="subtitle is-6">Con Scratch</p>
                                                </div>
                                                {/*has-background-link*/}
                                                <div  className="media-right cardDate">
                                                    <div className="level">
                                                        <div className="level-item has-text-centered">
                                                            <div>
                                                                <p className="heading">Marzo</p>
                                                                <p className="title has-text-white">7</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Link>

                                <Link to={`/course/1`}>
                                    <div className="card cardCurso" id="item2" onMouseOver={() => itemOver("#item2")} onMouseOut={() => itemOut("#item2")}>

                                        <div className="card-content">
                                            <div className="media">
                                                <div className="media-left">
                                                    <figure className="image is-64x64">
                                                        <img src='../Assets/Images/dibujo.png' alt="Placeholder image" />
                                                    </figure>
                                                </div>
                                                <div className="media-content">
                                                    <p className="title is-4">Dibujo & Pintura</p>
                                                    <p className="subtitle is-6">Con Lapices de color</p>
                                                </div>

                                                <div  className="media-right cardDate">
                                                    <div className="level">
                                                        <div className="level-item has-text-centered">
                                                            <div>
                                                                <p className="heading">Marzo</p>
                                                                <p className="title has-text-white">7</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Link>

                                <Link to={`/course/2`}>
                                    <div className="card cardCurso" id="item3" onMouseOver={() => itemOver("#item3")} onMouseOut={() => itemOut("#item3")}>

                                        <div className="card-content">
                                            <div className="media">
                                                <div className="media-left">
                                                    <figure className="image is-64x64">
                                                        <img src='../Assets/Images/paint.png' alt="Placeholder image" />
                                                    </figure>
                                                </div>
                                                <div className="media-content">
                                                    <p className="title is-4">Pintura con Acuarela</p>
                                                    <p className="subtitle is-6">Con pinceles y acuarela</p>
                                                </div>

                                                <div  className="media-right cardDate">
                                                    <div className="level">
                                                        <div className="level-item has-text-centered">
                                                            <div>
                                                                <p className="heading">Marzo</p>
                                                                <p className="title has-text-white">7</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Link>

                                <Link to={`/course/3`}>
                                    <div className="card cardCurso" id="item4" onMouseOver={() => itemOver("#item4")} onMouseOut={() => itemOut("#item4")}>

                                        <div className="card-content">
                                            <div className="media">
                                                <div className="media-left">
                                                    <figure className="image is-64x64">
                                                        <img src='../Assets/Images/diseno-grafico.png' alt="Placeholder image" />
                                                    </figure>
                                                </div>
                                                <div className="media-content">
                                                    <p className="title is-4">Diseño Gráfico</p>
                                                    <p className="subtitle is-6">Con Photoshop</p>
                                                </div>

                                                <div  className="media-right cardDate">
                                                    <div className="level">
                                                        <div className="level-item has-text-centered">
                                                            <div>
                                                                <p className="heading">Marzo</p>
                                                                <p className="title has-text-white">7</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Link>

                                <Link to={`/course/4`}>
                                    <div className="card cardCurso" id="item5" onMouseOver={() => itemOver("#item5")} onMouseOut={() => itemOut("#item5")}>

                                        <div className="card-content">
                                            <div className="media">
                                                <div className="media-left">
                                                    <figure className="image is-64x64">
                                                        <img src='../Assets/Images/modelado-3d.png' alt="Placeholder image" />
                                                    </figure>
                                                </div>
                                                <div className="media-content">
                                                    <p className="title is-4">Diseño y Modelado 3D</p>
                                                    <p className="subtitle is-6">Modelado 3D</p>
                                                </div>

                                                <div  className="media-right cardDate">
                                                    <div className="level">
                                                        <div className="level-item has-text-centered">
                                                            <div>
                                                                <p className="heading">Marzo</p>
                                                                <p className="title has-text-white">7</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Link>

                            </div>
                        </div>

                    </section >


                    <section className="section">
                        <div className="container my-6">

                            <div className="columns">
                                <div className="column">
                                    <p className="title">
                                        ¿Quiénes Somos?
                                    </p>
                                    <p className="px-2 has-text-justified is-size-5"> “<strong>La visión</strong> de TechKids es hacer accesible a niños y jóvenes en todas partes de Bolivia nuestra metodología STEAM, logrando la incorporación de la ciencia, tecnología, ingeniería, arte y matemáticas dentro de los contenidos curriculares de las escuelas.
                                    </p>
                                    <p className="px-2 has-text-justified is-size-5">
                                        “<strong>La misión</strong>  de TechKids desarrollar una enseñanza de calidad con bases en una metodología STEAM dirigida a la formación de niños y jóvenes desplegando sus cualidades únicas, motivamos su autonomía para enfrentar los retos posteriores en su vida escolar y social, todo esto a través de programas y cursos con enfoque científico-tecnológico.
                                    </p>
                                </div>
                                <div className="column">
                                    <div className="mx-6 my-6">
                                        <img src='../Assets/Images/LogoTechkids.svg' alt="Placeholder image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="section">

                        <div className="container">
                            <div className="columns">
                                <div className="column">
                                    <div className="mx-6 my-0">
                                        <img src='../Assets/Images/steam2.png' alt="Placeholder image"/>
                                    </div>
                                </div>
                                <div className="column">
                                    <p className="title">
                                        ¿Qué son las habilidades STEAM?
                                    </p>
                                    <p className="px-2 has-text-justified is-size-5">
                                        Las siglas STEAM forman un acrónimo en inglés que surge de las palabras Ciencia, Tecnología, Ingeniería, Arte y Matemáticas (Science, Technology, Engineering, Arts, Mathematics). Aunque en los años 90 ya se hablaba de habilidades STEM, en los últimos años se ha añadido el componente creativo, abierto e innovador que aportan las artes para acabar adoptando el término STEAM.
                                    </p>
                                    <p className="px-2 has-text-justified is-size-5">
                                        En TechKids queremos apostar por acercar las habilidades científicas y tecnológicas a los más pequeños y potenciar un nuevo modelo de aprendizaje práctico y lúdico que fomente las llamadas habilidades STEAM en cada uno de ellos, potenciando ante todo las cualidades y virtudes con las que cuenta cada estudiante.
                                    </p>
                                </div>

                            </div>


                        </div>


                    </section>

                </div>
            );
    }
}


export default HomeComponent;

