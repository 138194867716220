import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import {Link} from "react-router-dom";

class FooterComponent extends Component {
    render() {
        return (
            <div>
                <footer className="footer mt-6 footerStyle">
                    <div className="is-flex is-justify-content-center mb-6">
                        <span className="icon is-large mx-3">
                            <a href="https://www.facebook.com/TechkidsBolivia" target="_blank">
                                <FontAwesomeIcon icon={brands('facebook-square')} size="3x" />
                            </a>
                        </span>
                        <span className="icon is-large mx-3">
                            <a href="https://wa.me/59177412744?text=Hola+Techkids" target="_blank">
                                <FontAwesomeIcon icon={brands('whatsapp')} size="3x" />
                            </a>
                        </span>
                        <span className="icon is-large mx-3">
                            <a href="https://www.youtube.com/channel/UCZytMWjLuJYZW0J6nCXU3uQ" target="_blank">
                                <FontAwesomeIcon icon={brands('youtube')} size="3x" />
                            </a>
                        </span>
                    </div>
                    <div className="content has-text-centered">
                        <img src='../Assets/Images/LogoTechkids Footer.svg'
                             alt="Techkids: a modern CSS framework based on Flexbox" width="112" height="28" />
                        <p>
                            <strong>Techkids</strong>
                            ™ © 2020-2022
                            Bolivia.
                        </p>
                    </div>
{/*                    <ul className="is-flex is-flex-direction-row is-justify-content-center">
                        <Link to={`/`} className="px-1">Inicio</Link>
                        <Link to={`/`} className="px-1">Cursos</Link>
                        <Link to={`/course/0`} className="px-1">Contacto</Link>
                    </ul>*/}
                </footer>
            </div>
        );
    }
}

export default FooterComponent;