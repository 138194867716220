import React, {Component} from 'react';
import { Route, Routes, useParams} from "react-router-dom";


import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import HomeComponent from "./HomeComponent";
import {COURSES} from "../shared/courses";
import CourseDetail from "./CourseDetail";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses:COURSES
        };
    }

    render(){
        const CourseWithId = ({match}) => {

            let { courseId } = useParams();
            console.log(courseId);
            return(
                <CourseDetail course={this.state.courses.filter((course) => course.id === parseInt(courseId,10))[0]}
                            />
            );
        };

        return (
            <div>
                <HeaderComponent />
                <Routes >
                    <Route path='/course/:courseId' element={ <CourseWithId /> }/>
                    <Route exact path='/' element={ <HomeComponent />} />
                </Routes >
                <FooterComponent/>
            </div>

        );
    }

}

export default Main;
